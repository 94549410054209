import { create } from 'zustand';

export type LoadingState = {
    isLoading: boolean;
};

export type LoadingActions = {
    changeLoading: (is: boolean) => void;
};

export type LoadingStore = LoadingState & LoadingActions;

export const initLoadingStore = (): LoadingState => {
    return {
        isLoading: false,
    };
};

export const defaultInitState: LoadingState = {
    isLoading: false,
};

export const useLoadingStore = create<LoadingStore>()((set, get) => ({
    ...defaultInitState,
    changeLoading: (is) => {
        set((state) => ({ isLoading: is }));
    },
}));
